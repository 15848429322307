import Vue from 'vue'
import VueRouter from 'vue-router'
import UvodniStranka from "@/views/UvodniStranka";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'UvodniStranka',
    component: UvodniStranka,
  },
  {
    path: '/statistiky',
    name: 'Statistiky',
    component: () => import(/* webpackChunkName: "about" */ '../views/Statistiky.vue'),
  },
  {
    path: '/nova-poptavka-prodej',
    name: 'NovaPoptavkaProdej',
    component: () => import(/* webpackChunkName: "about" */ '../views/NovaPoptavkaProdej.vue'),
  },
  {
    path: '/nova-poptavka-vykup',
    name: 'NovaPoptavkaVykup',
    component: () => import(/* webpackChunkName: "about" */ '../views/NovaPoptavkaVykup.vue'),
  },
  {
    path: '/nova-poptavka-pronajem',
    name: 'NovaPoptavkaPronajem',
    component: () => import(/* webpackChunkName: "about" */ '../views/NovaPoptavkaPronajem.vue'),
  },
  {
    path: '/nova-poptavka-potvrzeni/:id',
    name: 'NovaPoptavkaPotvrzeni',
    component: () => import(/* webpackChunkName: "about" */ '../views/NovaPoptavkaPotvrzeni.vue'),
  },
  {
    path: '/prehled-poptavek',
    name: 'PrehledPoptavek',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrehledPoptavek.vue'),
  },
  {
    path: '/nastaveni',
    name: 'Nastaveni',
    component: () => import(/* webpackChunkName: "about" */ '../views/Nastaveni.vue'),
  },
  {
    path: '/prehled-odmen',
    name: 'PrehledOdmen',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrehledOdmen.vue'),
  },
  {
    path: '/moje-odmeny',
    name: 'MojeOdmeny',
    component: () => import(/* webpackChunkName: "about" */ '../views/MojeOdmeny.vue'),
  },
  {
    path: '/debuger-test',
    name: 'Debuger',
    component: () => import(/* webpackChunkName: "about" */ '../views/Debuger.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
