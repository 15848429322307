<template>
    <div>
        <div class="row header-row-bottom mt-2" v-if="zobrazitButtonNovaPoptavka == 1">
            <div class="col-12">
                <router-link class="btn btn-success btn-block form-control btn-lg" to="/nova-poptavka-prodej" v-if="zobrazitProdej == 1">
                    <i class="fas fa-plus"></i> Nová poptávka - <small>prodej</small>
                </router-link>
            </div>
            <div class="col-12 mt-3">
                <router-link class="btn btn-success btn-block form-control btn-lg" to="/nova-poptavka-pronajem" v-if="zobrazitPronajem == 1">
                    <i class="fas fa-plus"></i> Nová poptávka - <small>pronájem</small>
                </router-link>
            </div>
            <div class="col-12 mt-3">
                <router-link class="btn btn-success btn-block form-control btn-lg" to="/nova-poptavka-vykup" v-if="zobrazitVykup == 1">
                    <i class="fas fa-plus"></i> Nová poptávka - <small>výkup</small>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "UvodniStranka",
    data() {
        return {
            zobrazitButtonNovaPoptavka: 1,
            zobrazitPronajem: 0,
            zobrazitProdej: 0,
            zobrazitVykup: 0,
        }
    },
    created() {
        this.zobrazitPronajem = localStorage.getItem('userPravoPronajem');
        this.zobrazitProdej = localStorage.getItem('userPravoProdej');
        this.zobrazitVykup = localStorage.getItem('userPravoVykup');
    },
    metaInfo() {
        return {
            title: "VZV Servis | VZV.cz",
            meta: [
                { name: 'description', content:  'VZV.cz - Aplikace pro sběr poptávek od servisních externích společností.'},
                { property: 'og:title', content: "VZV Servs | VZV.cz"},
                { property: 'og:site_name', content: 'VZV Servis'},
                {property: 'og:type', content: 'website'},
                {name: 'robots', content: 'noindex,nofollow'}
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .btn-lg {
        padding: .5rem 1rem;
        font-size: 2rem;
        border-radius: .3rem;
        margin-top: 1rem;
    }
</style>