import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
Vue.config.productionTip = false


let development = true;

let baseUrl = window.location.href;
let baseUrlSplit = baseUrl.split('//');

if (baseUrl.indexOf('servis.vzv.cz') > 0)
{
  development = false;
}

let protocol = baseUrlSplit[0].replaceAll(':', '');

let baseUrlSplitSingles = baseUrlSplit[1].split('/');

let domainWithPort = baseUrlSplitSingles[0];
let domainWithPortSplit = domainWithPort.split(':');

let domain = domainWithPortSplit[0];
let port = domainWithPortSplit[1];


window.apiUrl = protocol + '://' + domain  + ':8081/v2/vGSlfqjd/aplikace-poptavky/';
window.appUrl = protocol + '://' + domain + ':8081/';

if (development == false)
{
  window.apiUrl = 'https://rest.vzv.cz/v2/vGSlfqjd/aplikace-poptavky/';
  window.appUrl = 'http://servis.vzv.cz/';
}

export const bus = new Vue();

Vue.prototype.$appUrl = window.appUrl;
Vue.prototype.$apiUrl = window.apiUrl;
Vue.prototype.$adminDir = window.adminDir;
Vue.prototype.$appDir = window.appDir;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
