<template>
    <div class="container-fluid">

        <div class="row">
            <div class="col-md-12 text-center">
                <img src="../assets/img/logo_vzv.png" alt="VZV.cz" class="img-fluid img-logo">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center">
                <h1>VZV Servis</h1>
            </div>
        </div>
        <div class="row login-form">
            <div class="col-10 offset-1 text-left login-form-col">
                <!-- <label for="uzivatelske_jmeno" class="form-label">Uživatelské jméno</label> -->
                <div class="input-group input-group-red mb-3">
                    <span class="input-group-text input-group-text-red">
                        <i class="fas fa-user"></i>
                    </span>
                    <input type="text" class="form-control" id="uzivatelske_jmeno" v-model="login">
                </div>

                <!-- <label for="uzivatelske_heslo" class="form-label">Heslo</label> -->
                <div class="input-group input-group-red mb-3">
                    <span class="input-group-text input-group-text-red">
                        <i class="fas fa-lock"></i>
                    </span>
                    <input type="password" class="form-control" id="uzivatelske_heslo" v-model="password">
                </div>

                <div class="row mb-2 mt-2" v-if="chybovaHlaska != ''">
                    <div class="col-12 text-center text-danger">
                        {{ chybovaHlaska }}
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-right">
                        <button type="button" class="btn btn-primary btn-sm btn-red" @click="prihlasit">Přihlásit se</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axiosApiPost from "../axiosApiMethod";
import {bus} from "/src/main";
export default {
    components: {axiosApiPost},
    name: "Login",
    data() {
        return {
            login: '',
            password: '',
            chybovaHlaska: ''
        }
    },
    methods: {
        prihlasit()
        {
            axiosApiPost('prihlaseni-uzivatele', {login: this.login, password: this.password}, 'data').then( result => {
                if (result.id_poptavky_osoby != null && result.id_poptavky_osoby != '')
                {
                    localStorage.setItem('userIdAplikacePoptavky', result.id_poptavky_osoby);
                    localStorage.setItem('userPravoProdej', result.pravo_prodej);
                    localStorage.setItem('userPravoPronajem', result.pravo_pronajem);
                    localStorage.setItem('userPravoVykup', result.pravo_vykup);
                    localStorage.setItem('userSurname', result.prijmeni + ' ' + result.jmeno);
                    bus.$emit('loginUser', true);
                    location.reload();
                }
                else
                {
                    this.chybovaHlaska = 'Účet neexistuje nebo zadáváte špatné heslo';
                }
            });

        }
    },
    metaInfo() {
        return {
            title: "Přihlášení | VZV.cz",
            meta: [
                { name: 'description', content:  'VZV.cz - Aplikace pro sběr poptávek od servisních externích společností.'},
                { property: 'og:title', content: "Přihlášení | VZV.cz"},
                { property: 'og:site_name', content: 'Login page'},
                {property: 'og:type', content: 'website'},
                {name: 'robots', content: 'noindex,nofollow'}
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .img-logo {
        margin-top: 1em;
        margin-bottom: 0.5em;
        max-width: 80%;
    }
    .login-form {
        margin-top: 2em;
        .login-form-col {
            border: 1px solid var(--border-color);
            padding: 1em;
            border-radius: 0.5em;
        }
    }
    h1 {
        font-weight: bold;
    }


</style>