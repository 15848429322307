<template>
    <div>
        <div class="container-fluid">
            <div class="header-row">
                <div class="row header-row-top">
                    <div class="col-4 col-md-2">
                        <router-link to="/">
                            <img src="../assets/img/logo_vzv.png" alt="VZV.cz" class="img-fluid img-logo">
                        </router-link>
                    </div>
                    <div class="col-6 col-md-9 text-center">
                        <div class="row header-row-kontakty">
                            <div class="col-12 col-md-6 text-align-query-right">
                                <div class="mt-1"><i class="fas fa-mobile-alt mt-1"></i> <a href="tel:608166056">608 166 056</a> </div>
                            </div>
                            <div class="col-12 col-md-6 text-align-query-left">
                                <div class="mt-1"><i class="far fa-envelope"></i> <a href="mailto:prodej@vzv.cz">prodej@vzv.cz</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 text-right">
                        <button type="button" class="btn btn-dark" @click="nastavZobrazeniPravehoMenu(1)">
                            <i class="fas fa-bars"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="body-row">
                <router-view/>
            </div>
            <div class="footer-row">

            </div>
        </div>
        <div class="nav-right" v-if="zobrazitPraveMenu == 1">
            <div class="nav-right-overlay" @click="nastavZobrazeniPravehoMenu(0)"></div>
            <div class="nav-right-menu w3-animate-right">
                <div class="nav-right-menu-wrap">
                    <div class="row">
                        <div class="col-12 text-right">
                            <button class="btn btn-dark btn-outline-light btn-sm" @click="nastavZobrazeniPravehoMenu(0)">
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <ul @click="nastavZobrazeniPravehoMenu(0)">
                                <li>
                                    <router-link to="/" class="first-a">
                                        <i class="fas fa-tachometer-alt"></i> Úvodní strana
                                    </router-link>
                                </li>
                                <li v-if="zobrazitProdej == 1">
                                    <router-link to="/nova-poptavka-prodej">
                                        <i class="fas fa-plus"></i> Nová poptávka - <small>prodej</small>
                                    </router-link>
                                </li>
                                <li v-if="zobrazitPronajem == 1">
                                    <router-link to="/nova-poptavka-pronajem">
                                        <i class="fas fa-plus"></i> Nová poptávka - <small>pronájem</small>
                                    </router-link>
                                </li>
                                <li v-if="zobrazitVykup == 1">
                                    <router-link to="/nova-poptavka-vykup">
                                        <i class="fas fa-plus"></i> Nová poptávka - <small>výkup</small>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/prehled-poptavek">
                                        <i class="fas fa-list"></i> Přehled poptávek
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/statistiky">
                                        <i class="fas fa-atom"></i> Statistiky
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/prehled-odmen">
                                        <i class="fas fa-atom"></i> Přehled odměn
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/moje-odmeny">
                                        <i class="fas fa-atom"></i> Moje odměny
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/nastaveni">
                                        <i class="fas fa-user-cog"></i> Nastavení
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="nav-right-menu-logout text-center nav-right-logout" @click="nastavZobrazeniPravehoMenu(0)">
                    <br>
                    <router-link to="/nastaveni">
                        {{ prijmeniJmeno }}
                    </router-link>
                    <br>
                    <span @click="odhlasitSe"><i class="fas fa-sign-out-alt"></i> Odhlásit se</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from "../main";

export default {
    name: "Layout",
    data() {
        return {
            zobrazitButtonNovaPoptavka: 1,
            zobrazitPraveMenu: 0,
            prijmeniJmeno: '',
            zobrazitPronajem: 0,
            zobrazitProdej: 0,
            zobrazitVykup: 0
        }
    },
    created() {
        bus.$on('showNovaPoptavka', (data) => {
            if (data == true)
            {
                this.zobrazitButtonNovaPoptavka = 1;
            }
            else
            {
                this.zobrazitButtonNovaPoptavka = 0
            }
        });
        this.prijmeniJmeno = localStorage.getItem('userSurname');
        this.zobrazitPronajem = localStorage.getItem('userPravoPronajem');
        this.zobrazitProdej = localStorage.getItem('userPravoProdej');
        this.zobrazitVykup = localStorage.getItem('userPravoVykup');
    },
    methods: {
        nastavZobrazeniPravehoMenu(typNastaveni)
        {
            this.zobrazitPraveMenu = typNastaveni;
        },
        odhlasitSe()
        {
            localStorage.removeItem('userIdAplikacePoptavky');
            bus.$emit('loginUser', false);
            location.reload();
        }
    }
}
</script>

<style lang="scss" scoped>
    .header-row {
        .header-row-top {
            img {
                width: 100%;
                margin: 10px 6px;
            }
            .btn {
                margin-top: 8px;
            }
            border: 1px solid var(--border-color);
        }
        .header-row-bottom {
            padding-top: 8px;
        }
    }
    .nav-right-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.4;
        z-index: 998;
    }
    .nav-right-menu {
        width: 250px;
        position: fixed;
        right: 0;
        top: 0;
        height: 100%;
        background: black;
        z-index: 999;
        .btn  {
            margin-right: 12px;
            margin-top: 8px;
        }

        ul {
            list-style: none;
            padding-left: 0px;

        }

        li {
            margin-left: 0px;
            padding-left: 14px;
            padding-right: 14px;

        }
        a {
            color: white;
            text-decoration: none;
            float: left;
            width: 100%;
            /*margin-top: 20px;*/
            padding-bottom: 10px;
            padding-top: 10px;
            /*border-bottom: 1px solid var(--border-color);*/
            border-bottom: 1px solid #3d3d3d;
            i {
                margin-right: 7px;
            }
        }
        .first-a {
            /*border-top: 1px solid #3d3d3d;*/
        }
        a:hover {
            color: lightgrey;
            cursor: pointer;
        }
    }
    .nav-right-menu-wrap {
        height: 88%;
        border-bottom: 1px solid var(--border-color);
    }
    .nav-right-logout a{
        margin-top: 0px;
        margin-bottom: 10px;
        float: none;
        font-size: 13px;
        border-bottom: none;
    }
    .nav-right-logout {
        color: white;
        font-size: 13px;
    }
    .nav-right-logout span {
        float: left;
        margin: 10px;
        position: absolute;
        text-align: center;
        left: 55px;
        color: #ff0303;
    }
    .btn-check:focus+.btn-success, .btn-success:focus {
        box-shadow: none;
    }

    .body-row {

    }

    .footer-row {

    }

    a {
        text-decoration: none;
    }

    @media (min-width: 768px) {
        .header-row-kontakty {
            margin-top: 20px;
            .text-align-query-left {
                text-align: left;
            }
            .text-align-query-right {
                text-align: right;
            }
        }
    }

</style>