import axios from "axios";

export default function axiosApiPost(url, params, responseKey = '')
{
    const paramsEdit = new URLSearchParams();
    $.each(params, function (key, value) {
        paramsEdit.append(key, value);
    });

    let userId = localStorage.getItem('userIdAplikacePoptavky');
    if (userId != null && userId != '')
    {
        //btoa(unescape(encodeURIComponent(userId)))
        paramsEdit.append('id_poptavky_osoby', userId);
    }
    let configAxios = {
        headers: {
            "Accept":"application/json",
            "Content-type":"application/x-www-form-urlencoded"
        }
    }

    if ((userId != null && userId != '') || url == 'prihlaseni-uzivatele')
    {
        return new Promise((resolve, reject) => {
            axios.post(apiUrl + url, paramsEdit, configAxios).then(function (response) {
                if (response.data.status == 'ok')
                {
                    if (responseKey != '')
                    {
                        resolve(response.data[responseKey]);
                    }
                    else
                    {
                        resolve(response);
                    }
                }
            }).catch(function (error)
            {
                console.log(error);
                reject(error);
            });
        });
    }
}