<template>
  <div id="app">
      <template v-if="prihlasenyUzivatel == 1">
        <layout />
      </template>
      <template v-if="prihlasenyUzivatel == 0">
        <login />
      </template>
  </div>
</template>
<script>
import Login from "./views/Login";
import {bus} from "/src/main";
import Layout from "./components/Layout";
export default {
    components: {Layout, Login},
    data() {
        return {
            prihlasenyUzivatel: 0
        }
    },
    created()
    {
        bus.$on('loginUser', (data) => {
            if (data == true)
            {
                this.prihlasenyUzivatel = 1;
            }
            else
            {
                this.prihlasenyUzivatel = 0;
            }
        });
        if (localStorage.getItem('userIdAplikacePoptavky') != '' && localStorage.getItem('userIdAplikacePoptavky') != null)
        {
            this.prihlasenyUzivatel = 1;
        }
        //
    }
}
</script>
<style lang="scss">

</style>
